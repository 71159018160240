.container {
    margin: 50px;
    position: absolute;
    animation: zoomInZoomOut 1s infinite;
  }
  
  .animation {
    animation: zoom 0.1s;
    animation-duration: 1s;
 
  }
    
  @keyframes zoomInZoomOut {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    }
  }