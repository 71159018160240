* {
  padding: 0;
  margin: 0;
}



::-webkit-scrollbar {
  width: 10px;      
  height: 10px;         /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #2C3E50; 
  border-radius: 20px;         /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #e7505a;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */

}
